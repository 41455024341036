import React from 'react';
import { Flex, Box } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import useTranslation from '@hooks/useTranslation';
import gameConfig from '@content/gameconfig';
import LanguageModalButton from './LanguageModalButton';

const Wrapper = styled(Box).attrs({ as: 'footer' })(p =>
  css({
    isolation: 'isolate',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    pl: [1, 4],
    mb: p.showLanguageToggle ? [-1, 0] : 0,
  }),
);

const Footer = () => {
  const { t } = useTranslation();
  const { Show_Language_Toggle = true } = gameConfig;
  return (
    <Wrapper showLanguageToggle={Show_Language_Toggle}>
      <Flex justifyContent="space-between">
        <Flex />
        {Show_Language_Toggle && (
          <Flex flex={1} justifyContent="flex-end">
            <LanguageModalButton />
          </Flex>
        )}
      </Flex>
    </Wrapper>
  );
};

export default Footer;
