import { SystemStyleObject } from '@styled-system/css';
import styled, { StyledComponent } from 'styled-components';
import {
  compose,
  shadow,
  ShadowProps,
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
  // TODO: This interface has most of the 'missing' props, but there are issues
  // with the props not matching up - i.e. "color" cannot be "string" or "undefined".
  // needs more investigation.
  // StylesProps,
} from 'styled-system';
import Box, { BoxProps } from './Box';

export type BaseTextProps =
  | ShadowProps
  | TextStyleProps
  | TypographyProps
  | BoxProps;

interface OtherProps {
  textDecoration?: string;
  flexDirection?: SystemStyleObject;
  alignItems?: string;
  alignSelf?: string;
  fontSize?: string;
  fontWeight?: number;
}

const Text: StyledComponent<'div', any, BaseTextProps & OtherProps, never> =
  styled(Box)(compose(shadow, textStyle, typography));
export default Text;
