/** this slice pertains to the sending of data to our pixel */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseTheme } from 'workspace-core-theme/theme.interface';

export const initialThemeState: BaseTheme | object = {};

export const themeSlice = createSlice({
  name: 'theming',
  initialState: initialThemeState,
  reducers: {
    setTheme: (state, action: PayloadAction<BaseTheme>) => ({
      ...state,
      ...action.payload,
    }),

    setColors: (state, action: PayloadAction<{ [x: string]: string }>) => {
      state.colors = { ...state.colors, ...action.payload };
    },
  },
});

export const { setTheme, setColors } = themeSlice.actions;

export default themeSlice.reducer;
