import React, { ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import { Button } from 'workspace-core-ui';
import { logItem } from '@slices/loggingSlice';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { WINDOW_HASH } from '@sharedConstants';
import getQuestionNameFromCurrentRoute from '@utils/getQuestionNameFromCurrentRoute';

interface Props {
  children: ReactNode;
  delegated?: { [x: string]: any };
}

const NextButton = styled(Button).attrs({
  forwardedAs: Link,
})({
  textDecoration: 'none',
});

const NextPageButton = ({ children, ...delegated }: Props) => {
  const dispatch = useAppDispatch();
  const { nextRoute, currentRoute } = useAppSelector(state => state.route);
  const to = nextRoute?.url ? nextRoute.url + WINDOW_HASH : `/${WINDOW_HASH}`;
  return (
    <NextButton
      buttonSize="medium"
      variant="secondary"
      onPress={() => {
        dispatch(
          logItem({
            collection_name: 'events',
            event_type: 'click',
            location: getQuestionNameFromCurrentRoute(currentRoute),
            target: 'custom_next_page_button',
          }),
        );
      }}
      to={to}
      {...delegated}
    >
      {children}
    </NextButton>
  );
};

export default NextPageButton;
