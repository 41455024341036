import React, { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { Notification, Flex, Label } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import { removeAllNotifications } from '@slices/gameStateSlice';

// defaults should be ~5000
const NOTIFICATION_TIMEOUT = 5000;

const Wrapper = styled(Flex)({
  width: '100vw',
  height: '100vh',
  flexDirection: 'column',
});

const ListWrapper = styled(Flex)(
  css({
    zIndex: 'sun',
    position: 'fixed',
    pointerEvents: 'none',
    top: 0,
    bottom: 0,
    left: 0,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    p: 0,
    pl: [4, 7, 7],
  }),
);

const NotificationSystem = () => {
  const { notifications } = useAppSelector(state => state.gameState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // NOTE: for testing/viewing a notification
    // if (notifications.length === 0) {
    //   dispatch(addNewNotification('text'));
    // }
    if (notifications.length > 0) {
      // if we have notifications, perform a removal of them after few secs
      const timeout = setTimeout(() => {
        dispatch(removeAllNotifications());
      }, NOTIFICATION_TIMEOUT);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [dispatch, notifications]);

  return (
    <Wrapper>
      <ListWrapper as="ul">
        <AnimatePresence>
          {[...notifications].reverse().map(({ id, text }, index) => (
            <Notification
              position="relative"
              m={3}
              ml={0}
              key={id}
              exit={{
                opacity: 0,
                scale: 0.5,
                transition: { duration: 0.2 + index / 10 },
              }}
              transition={{
                when: 'beforeChildren',
                staggerChildren: 0.3,
              }}
            >
              <Flex alignItems="flex-end">
                <Label color="surface" variant="l2">
                  {text}
                </Label>
              </Flex>
            </Notification>
          ))}
        </AnimatePresence>
      </ListWrapper>
    </Wrapper>
  );
};

export default NotificationSystem;
