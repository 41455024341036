import React, { useEffect } from 'react';
import ClientOnly from '@components/ClientOnly';

const Redirector = ({ to = '/', timeout = 3000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = to;
    }, timeout);
    return () => clearTimeout(timer);
  }, []);
};

const RedirectorClientOnly = props => (
  <ClientOnly>
    <Redirector {...props} />
  </ClientOnly>
);

export default RedirectorClientOnly;
