import LevelStar from '@components/LevelStar';
import { useAppSelector } from '@hooks/redux-hooks';
import React from 'react';
import { Flex } from 'workspace-core-ui';

// TODO: maybe expose props here later
const StarBadge = () => {
  const { currentLevel } = useAppSelector(s => s.levelling);

  return (
    <Flex width="100%" pb={10} sx={{ placeContent: 'center' }}>
      <LevelStar count={currentLevel} isPeacocking />
    </Flex>
  );
};

export default StarBadge;
