import React from 'react';
import { useAppDispatch } from '@hooks/redux-hooks';
import { logItem } from '@slices/loggingSlice';
import { Button } from 'workspace-core-ui';
import useTranslation from '@hooks/useTranslation';
import copyTextToClipboard from '@utils/copyTextToClipboard';

// Grab the session id so it can be used as content/rcp tracking
const SessionId = ({ ...delegated }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sessionIdToCopy =
    typeof window !== 'undefined' ? window.session_id : 'ERR';
  return (
    <Button
      buttonSize="medium"
      variant="secondary"
      onPress={() => {
        dispatch(
          logItem({
            collection_name: 'events',
            event_type: 'click',
            target: 'copy_session_id_button',
          }),
        );
        copyTextToClipboard({
          text: sessionIdToCopy,
          copiedNotification: t('Copied'),
          dispatch,
          showVerboseNotification: true,
        });
      }}
      {...delegated}
    >
      {sessionIdToCopy}
    </Button>
  );
};

export default SessionId;
