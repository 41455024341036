import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { VisuallyHidden } from 'react-aria';
import { motion } from 'framer-motion';

export interface ProgressBarProps {
  value: number;
  /** if true, the entire shape is not a squirqel anymore, but has the right most side lobbed off vertically */
  hasFlatRightEar: boolean;
}

const Wrapper = styled.div(({ hasFlatRightEar }) =>
  css({
    backgroundColor: 'transparent',
    boxShadow: t => `0 0 0 1px ${t.colors.foreground}`,
    borderRadius: hasFlatRightEar ? '8px 0px 0px 8px' : 8,
  }),
);

const BarWrapper = styled.div(
  css({
    borderRadius: '6px',
    overflow: 'hidden',
  }),
);

const Bar = styled.div(
  css({
    height: ['8px', '12px', '12px'],
    backgroundColor: t => t.colors.systemAccent,
    borderRadius: '32px',
  }),
);

const ProgressBar = ({
  value,
  hasFlatRightEar = true,
  ...delegated
}: ProgressBarProps) => (
  <Wrapper
    role="progressbar"
    aria-valuenow={value}
    aria-valuemin="0"
    aria-valuemax="100"
    hasFlatRightEar={hasFlatRightEar}
  >
    <VisuallyHidden>{value}%</VisuallyHidden>
    <BarWrapper>
      <Bar
        as={motion.div}
        initial={{ scaleX: 0 }}
        animate={{
          originX: 0,
          scaleX: value / 100,
        }}
        transition={{ duration: 0.8, type: 'tween' }}
        {...delegated}
      />
    </BarWrapper>
  </Wrapper>
);

export default ProgressBar;
