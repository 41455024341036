interface StandardShareProps {
  /** if not specified, defaults to current window */
  url?: string;
  popupWindowWidth: number;
  popupWindowHeight: number;
}

interface ShareApiProps {
  title: string;
  text: string;
}

export const isBrowser = typeof window !== `undefined`;
// not the most holistic way to check if on mobile but it works
export const isMobile = isBrowser && typeof window?.orientation !== 'undefined';
export const hasShareApi = isBrowser && window?.navigator.share !== undefined;

export const openStandardShare = ({
  url = '#',
  popupWindowWidth = 500,
  popupWindowHeight = 500,
}: StandardShareProps) => {
  let options = '';
  // if on mobile, just go to the link directly, skip the popup
  if (!isMobile) {
    url += '&display=popup';
    options = `toolbar=0,status=0,resizeable=1,width=${popupWindowWidth},height=${popupWindowHeight}`;
  }

  window.open(url, 'share', options);
};

// TODO: this is not implemented yet due to compat issues
export const openShareApi = async ({ title, text }: ShareApiProps) => {
  // this obj will be used if we have the share API accessible
  const shareDataForShareApi = {
    title,
    text,
    url: isBrowser
      ? window?.location.origin
      : 'https://digitalpublicsquare.org/',
  };
  if (hasShareApi && isBrowser) {
    try {
      await window?.navigator.share(shareDataForShareApi);
    } catch (err) {
      // alert(err);
      /// mb report the err somewhere
      // alert(err);
    }
  }
};
