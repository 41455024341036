import { Route, TypeOfScreen } from 'types';
import { COUNTABLE_SCREENS, TYPE_OF_SCREEN_ENUM } from '@sharedConstants';
import fisherYatesShuffle from '@utils/fisherYatesShuffle';
import fixedShuffle from '@utils/fixedShuffle';

/** a very game specific route shuffling function
 * It returns a given list of routes, and then shuffles them according to passes variables
 * It also appends an end route once shuffled
 */
interface routeShufflerProps {
  /** an unmolested list of routes supplied (typically) directly by api */
  allRoutes: Route[];
  /** optional seperate list of designated pooled routes */
  pooledRoutes?: Route[];
}
const routeShuffler = ({
  allRoutes,
  pooledRoutes = [],
}: routeShufflerProps): Route[] => {
  let tmpAllRoutes = [...allRoutes];
  // if we have a route with a typeOfScreen of "ClaimPoolStub", we need to swap it with items from routemaps pooled array
  let shuffledPooledRoutes = fisherYatesShuffle<Route>([...pooledRoutes]);

  if (
    shuffledPooledRoutes.length > 0 &&
    tmpAllRoutes.some(r => r.typeOfScreen === TYPE_OF_SCREEN_ENUM.ClaimPoolStub)
  ) {
    // swap out our ClaimPoolStub with the pooled items
    // do this until we have exhausted pooled routes
    tmpAllRoutes = tmpAllRoutes.map(route => {
      if (route.typeOfScreen === TYPE_OF_SCREEN_ENUM.ClaimPoolStub) {
        let chosenPooledRoute = null;

        // filter out shuffledPooled routes (inner) by the claimPoolStubs own pooling tags (screen)
        const poolingTagFilteredRoutes = shuffledPooledRoutes.filter(e =>
          e?.innerPoolingTags?.some(val =>
            route?.screenPoolingTags?.includes(val),
          ),
        );


        if (poolingTagFilteredRoutes?.length > 0) {
          // choose a route from our tag filtered routes
          chosenPooledRoute = poolingTagFilteredRoutes[0] as Route;
        }

        if (!chosenPooledRoute) {
          throw new Error(
            `Woops! We ran out of pooled routes to choose from. Please ensure that you have as many or more Pooling Tags defined in your Claims etc than you have defined in one of your Stream tables. Route name affected is ${
              route.name
            } on index: ${1}, its tags are ${route.screenPoolingTags?.toString()}`,
          );
        }
        // since we have a pooled route, we need to remove it (and any like it) from the master list as well
        shuffledPooledRoutes = shuffledPooledRoutes.filter(
          r => r.url !== chosenPooledRoute.url,
        );
        return {
          ...route,
          name: chosenPooledRoute.name,
          url: chosenPooledRoute.url,
          typeOfScreen: chosenPooledRoute.typeOfScreen,
          hasFollowup: route?.hasFollowup,
        };
      }
      return route;
    });
  }

  tmpAllRoutes = fixedShuffle({
    array: tmpAllRoutes,
    peg: { key: 'isSticky', value: true },
    softPeg: {
      key: 'typeOfScreen',
      value: TYPE_OF_SCREEN_ENUM.Interrupt,
    },
    // dynamicPeg: {
    //   key: 'isFromPool',
    //   value: true,
    // },
    // TODO: if we turn off randomization or maybe have controls for this in future, then the shuffling need not be done here
    shouldShuffleSoftPegs: true,
    shouldShuffleDynamicPegs: true,
    shouldShuffleRemaining: true,
  }) as Route[];

  // return these shuffled routes, but tack on an end game screen too
  return [
    ...tmpAllRoutes,
    {
      name: 'end',
      url: '/end',
      index: (tmpAllRoutes?.[tmpAllRoutes.length - 1]?.index ?? 0) + 1,
      typeOfScreen: TYPE_OF_SCREEN_ENUM.End,
      isSticky: true,
    },
  ];
};

export default routeShuffler;
