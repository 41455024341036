exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-end-tsx": () => import("./../../../src/pages/end.tsx" /* webpackChunkName: "component---src-pages-end-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-claim-tsx": () => import("./../../../src/templates/Claim.tsx" /* webpackChunkName: "component---src-templates-claim-tsx" */),
  "component---src-templates-demographics-tsx": () => import("./../../../src/templates/Demographics.tsx" /* webpackChunkName: "component---src-templates-demographics-tsx" */),
  "component---src-templates-interrupt-tsx": () => import("./../../../src/templates/Interrupt.tsx" /* webpackChunkName: "component---src-templates-interrupt-tsx" */),
  "component---src-templates-profile-question-tsx": () => import("./../../../src/templates/ProfileQuestion.tsx" /* webpackChunkName: "component---src-templates-profile-question-tsx" */)
}

